import React from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import { Button } from '@components';

export default function FourOhFour() {
  const { back } = useRouter();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Typography
        sx={{
          fontSize: 40,
        }}
      >
        404 - Not Found
      </Typography>
      <Button onClick={back}>Go back</Button>
    </Box>
  );
}
